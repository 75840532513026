<template>
  <div>
    <associatedOption
      type="combobox"
      :fieldAttributes="{ ...fieldAttributes, options: options }"
      :value="value"
      v-on="$listeners"
      :field="field"
      :templateContent="result"
    ></associatedOption>
  </div>
</template>
<script>
import associatedOption from "@/commonComponents/associatedOption.vue";
export default {
  props: ["fieldAttributes", "field", "result", "value"],
  components: { associatedOption },
  computed: {
    options() {
      let options;
      if (this.result.valueLine.resourceType == "simCard") {
        options = this.fieldAttributes.availableSimCards;
      } else if (this.result.valueLine.resourceType == "activeProbe") {
        options = this.fieldAttributes.allowedActiveProbes;
      }
      return options;
    },
  },
};
</script>